<template>
  <div>
    <h2 class="mb-base">{{$t('WROs')}}</h2>
    <head-component :noActions="true" :statusFilters="statusFilters" :selected="selected" @changeFilter="changeFilter" @filterValue="filterValue = $event" @loadShipments="loadStockRequests"></head-component>
    <shipblu-table
     :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="stockRequests"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Request ID')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Number of SKUs')}}</shipblu-th>
        <shipblu-th>{{$t('Total Quantity')}}</shipblu-th>
        <shipblu-th>{{$t('Fulfillment Center')}}</shipblu-th>
        <shipblu-th>{{$t('Number Of Vehicles')}}</shipblu-th>
        <shipblu-th>{{$t('Received On')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data"
          :to="{name: `${$store.state.AppActiveUser.userRole}-stock-request-view`, params: {stockID: data[indextr].id}}">
          <shipblu-td :data="data[indextr].requestID">
            {{ data[indextr].id }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].createdOn">
            {{ new Date(data[indextr].created).toLocaleDateString('fr-CA') }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].merchant">
            {{ data[indextr].merchant.name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].numberOfSKU">
            {{ data[indextr].number_of_skus }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].quantity">
            {{ data[indextr].total_quantity }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].fulfillmentCenter">
            {{ data[indextr].fulfillment_center.name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].vehicles">
            {{ data[indextr].num_vehicles }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].receivedOn">
            {{ data[indextr].received_date }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`">
            <div class="m-auto flex items-center justify-center font-semibold">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{$t(getStatusLabel(data[indextr]))}}</span>
            </div>
          </shipblu-td>

          <shipblu-td>
            <div @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                class="dd-actions cursor-pointer"
                @click="selected = []"
              >
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-more-horizontal"
                >
                </vs-button>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="createURO(data[indextr])"
                    :disabled="$store.state.AppActiveUser.userRole === 'fc-storing-agent'"
                  >
                    <span class="flex gap-2 items-center">
                      <feather-icon
                        icon="AlertCircleIcon"
                        svgClasses="h-4 w-4"
                      />
                      <span>{{$t('Create URO')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    :disabled="data[indextr].status !== 'received'"
                    @click="downloadPDF(data[indextr])"
                  >
                    <span class="flex gap-2 items-center">
                      <feather-icon
                        icon="PrinterIcon"
                        svgClasses="h-4 w-4"
                      />
                      <span>{{$t('Print WRO')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <template :disabled="$store.state.AppActiveUser.userRole !== 'fc-receiving-agent'">
                    <vs-dropdown-item
                      v-if="data[indextr].status === 'submitted'"
                      @click="addTracking('ready', data[indextr])"
                    >
                      <span class="flex gap-2 items-center">
                        <feather-icon
                          icon="AlertCircleIcon"
                          svgClasses="h-4 w-4"
                        />
                        <span>{{$t('Mark as Ready')}}</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      v-if="data[indextr].status === 'ready'"
                      @click="addTracking('received', data[indextr])"
                    >
                      <span class="flex gap-2 items-center">
                        <feather-icon
                          icon="AlertCircleIcon"
                          svgClasses="h-4 w-4"
                        />
                        <span>{{$t('Receive WRO')}}</span>
                      </span>
                    </vs-dropdown-item>
                  </template>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-unidentified-stock :UROModal="UROModal" :UROstock="UROstock" @UROModal="UROModal = $event"/>
  </div>
</template>

<script>
import common  from '@/assets/utils/common'
import {sendFulfillmentRequest} from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import HeadComponent from '../merchant/components/Header.vue'
import AddUnidentifiedStock from './components/AddUnidentifiedStock.vue'
import axios from 'axios'
import i18nData from '../../i18n/i18nData.js'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      stockRequests: [],
      statusFilters: [
        {
          name: 'All',
          value: 'all',
          color: ''
        },
        {
          name: 'submitted',
          value: 'submitted'
        },
        {
          name: 'Ready',
          value: 'ready'
        },
        {
          name: 'received',
          value: 'received'
        },
        {
          name: 'inbounded',
          value: 'inbounded'
        },
        {
          name: 'stored',
          value: 'stored'
        },
        {
          name: 'Cancelled',
          value: 'cancelled'
        }
      ],
      filters: [],
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      UROModal: false,
      UROstock: {},
      stockStatus: '',
      tableLoader: false,
      totalProductsReceived: 0
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadStockRequests()
    },
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadStockRequests()
      }
    }
  },
  methods: {
    addTracking (status, data) {
      this.totalProductsReceived = 0
      if (status === 'received') {
        this.stockStatus = 'received'
        this.stockRequestID = data.id
        data.products.forEach(item => {
          this.totalProductsReceived += item.received_quantity
        })
        this.$vs.dialog({
          color: 'success',
          title: 'Confirm',
          text: `${i18nData[this.$i18n.locale]['Are you sure you want to confirm receiving']} ${this.totalProductsReceived} ${i18nData[this.$i18n.locale]['Product(s)?']}`,
          accept: this.confirmAddTracking
        })
      } else {
        this.stockStatus = 'ready'
        this.stockRequestID = data.id
        this.confirmAddTracking()
      }
    },
    confirmAddTracking () {
      const tracking = {
        stock_request: this.stockRequestID,
        status: this.stockStatus
      }
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-requests/${this.stockRequestID}/tracking/`, 'post', tracking, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Stock request'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.stockStatus = ''
          this.loadStockRequests()
        }
      )
    },
    downloadPDF (stockRequest) {
      axios({
        url: `${process.env.VUE_APP_FULFILLMENT_URL}/api/v1/fc/stock-requests/${stockRequest.id}/pdf/`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'WRO.pdf')
        document.body.appendChild(link)
        link.click()
      })
    },
    createURO (data) {
      this.UROstock = data
      this.UROModal = true
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadStockRequests()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (stock) {
      return common.getOrderStatusColor(this.getStatusLabel(stock))
    },
    getStatusLabel (stock) {
      return common.getStatusLabel(stock, this.$store.state.AppActiveUser.userRole)
    },
    loadStockRequests () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}&status=${this.filters}&status_except=draft`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/stock-requests/${query}`, 'get', null, true,
        (response) => {
          this.stockRequests = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
          this.loadCreatedStocks()
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    loadCreatedStocks () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/stock-requests/?status=submitted', 'get', null, true,
        (response) => {
          localStorage.setItem('createdStockRequests', response.data.count)
        }
      )
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadStockRequestsSearch)
    },
    loadStockRequestsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadStockRequests()
    }
  },
  components: {
    HeadComponent,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    AddUnidentifiedStock,
    ShipbluPagination
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadStockRequests()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>